import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_FETCH_MOVIES = `${envValues.REACT_APP_API_ENDPOINT}/movies?active=true&`;
export const REACT_APP_API_FETCH_NOTIFICATIONS = `${envValues.REACT_APP_API_ENDPOINT}/notifications?`;


export const REACT_APP_API_FETCH_VENDOR = `${envValues.REACT_APP_API_ENDPOINT}/vendor?`;
export const REACT_APP_API_FETCH_CAFE = `${envValues.REACT_APP_API_ENDPOINT}/cafe?`;
export const REACT_APP_API_FETCH_CONTACT_US = `${envValues.REACT_APP_API_ENDPOINT}/enquiries?`;

export const REACT_APP_API_VENDORS_LIST = `${envValues.REACT_APP_API_ENDPOINT}/vendor/vendor-list?`;
export const REACT_APP_API_FETCH_ZIPCODES = `${envValues.REACT_APP_API_ENDPOINT}/cafe/zip-codes?`;
export const REACT_APP_API_FETCH_FEEDBACK = `${envValues.REACT_APP_API_ENDPOINT}/feedback?`;
export const REACT_APP_API_FETCH_UNAPPROVED_BANKS = `${envValues.REACT_APP_API_ENDPOINT}/payoutaccounts/unapproved-banks?`;
export const REACT_APP_API_FETCH_PAYOUT_REQUESTS = `${envValues.REACT_APP_API_ENDPOINT}/payoutaccounts/payout-requests?`;
export const REACT_APP_API_FETCH_DRINKTYPES = `${envValues.REACT_APP_API_ENDPOINT}/drinktypes?`;
export const REACT_APP_API_FETCH_MENUS = `${envValues.REACT_APP_API_ENDPOINT}/menus`;
export const REACT_APP_API_FETCH_CLAIM_REQUESTS = `${envValues.REACT_APP_API_ENDPOINT}/cafe/claim-requests`;
export const REACT_APP_API_APPROVE_CLAIM_REQUESTS = `${envValues.REACT_APP_API_ENDPOINT}/cafe/approve-cafe`;
export const REACT_APP_API_REJECT_CLAIM_REQUESTS = `${envValues.REACT_APP_API_ENDPOINT}/cafe/reject-cafe`;
export const REACT_APP_API_FETCH_CLAIM_REQUESTS_OUTSIDE = `${envValues.REACT_APP_API_ENDPOINT}/cafe/cafe-claim-requests?`;
export const REACT_APP_API_DRINKTYPES = `${envValues.REACT_APP_API_ENDPOINT}/drinktypes/list-drinktypes?`;
export const REACT_APP_API_ADDONS = `${envValues.REACT_APP_API_ENDPOINT}/addons`;





