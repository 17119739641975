/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEdit } from "@fortawesome/fontawesome-free-solid"; // Added faEdit icon for the edit button
import { Entry } from "../../redux/entrySlice";
import SelectBox from "../../components/SelectBox/SelectBox";
import PhoneNumberInput from "../../components/PhoneNumberBox/PhoneNumberBox";
import styles from "./editUserPopUp.module.scss";
import { TableContext } from "../../contexts/tableContext";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../components/TextField/TextField";
import EmailInput from "../../components/EmailBox/EmailBox";
import { REACT_APP_API_ROLES_DROPDOWN } from "../../redux/apiConstants";
import { Image } from "react-bootstrap";

const EditVendorPopup = React.memo(
  ({ vendorId, handleClose, setIsUpdated }) => {
    const [submitting, setSubmitting] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // New state to track edit mode
    const { formthemeStyle, bodyheader } = useContext(TableContext);
    const { errormsgStyle } = useContext(TableContext);
    const [user, setUser] = useState([]);
    const [error, setError] = useState(null);
    const [roles, setRoles] = useState([]);
    const dispatch = useDispatch();
    const { files } = useContext(TableContext);
    const {
      control,
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm();

    useEffect(() => {
      const url2 = REACT_APP_API_ROLES_DROPDOWN;
      fetch(url2)
        .then((resp) => resp.json())
        .then((resp) => {
          setRoles(resp.data.role);
        });

      const actionData = { actionUrl: `user/${vendorId}`, actionMethod: "get" };
      dispatch(Entry(actionData)).then((resp) => {
        if (resp.payload && resp.payload.data && resp.payload.data.user) {
          setUser(resp.payload.data.user);
          reset(resp.payload.data.user); // Reset the form with user data
        } else {
          console.error("User data not found in response:", resp);
          setUser(null);
        }
      });
    }, [vendorId]);

    const onSubmitdata = (data) => {
      if (data.name.trim().length === 0) {
        document.getElementById("name").focus();
      } else {
        setSubmitting(true);
        setError(null);
        const apiData = {
          name: data.name,
          email: data.email,
          phone: data.phone,
          File: files,
        };
        data.actionUrl = `user/${vendorId}`;
        data.actionMethod = "patch";
        data.apiData = apiData;
        dispatch(Entry(data)).then((resp) => {
          setSubmitting(false);
          if (resp.payload.code === 200) {
            toast.success("User updated successfully");
            setIsEditMode(false); // Switch back to view mode after update
            handleClose();
            setIsUpdated(true);
          } else {
            setError(resp.payload.message);
            toast.error("Error updating user");
          }
        });
      }
    };

    return (
      <div className={styles.popupbox}>
        <div className={styles.box} id={styles.box}>
          {/* Flex container for close button, heading, and edit button */}
          <div className="d-flex align-items-center gap-3">
            <button
              className={styles.closeicon}
              aria-label="Close"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {user.image && !isEditMode && (
                <Image
                  src={user.image}
                  alt="logo"
                  className={styles.profilepic}
                />
            )}
            {/* Conditionally render the heading based on isEditMode */}
            <h2 className={styles.heading}>
              {isEditMode ? "Edit Vendor Details" : "Vendor Details"}
            </h2>

            {/* Show edit button only when not in edit mode */}
            {!isEditMode && (
              <button
                className={`${styles.formbtnedit}`} // Add your custom class for styling
                onClick={() => setIsEditMode(true)}
                aria-label=""
              >
                <FontAwesomeIcon icon={faEdit} /> {/* Edit icon */}
                Edit
              </button>
            )}
          </div>

          <div className="container-fluid mt-5 edituserform">
            {user ? (
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <TextInput
                      className={styles.inputbox}
                      label="Name"
                      fieldName="name"
                      placeHolder="Enter Name"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      readOnly={!isEditMode} // Make this read-only in view mode
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <EmailInput
                      className={styles.inputbox}
                      label="Email"
                      fieldName="email"
                      register={register}
                      errors={errors}
                      isRequired
                      mandatory
                      placeHolder="Email"
                      readOnly={!isEditMode} // Make this read-only in view mode
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <PhoneNumberInput
                      label="Phone Number"
                      placeHolder="Phone Number"
                      className={styles.inputbox}
                      fieldName="phone"
                      register={register}
                      errors={errors}
                      readOnly={!isEditMode} // Make this read-only in view mode
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 col-sm-6">
                    <MultipleFileUpload
                      editFiles={user.image}
                      section="user"
                      disabled={!isEditMode} // Disable file upload in view mode
                    />
                  </div>
                </div>

                {/* Show submit button only in edit mode */}
                {isEditMode && (
                  <input
                    className={styles.formbtn}
                    type="submit"
                    disabled={submitting}
                    value={submitting ? "Please wait.." : "Update"}
                  />
                )}

                {error && <h6 className={styles.errormsg}>{error}</h6>}
              </form>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "130px",
                  color: "#39979d",
                }}
              >
                <SpinnerDotted />
                <p>No user data found</p>
              </div>
            )}

            <ToastContainer autoClose={2000} />
          </div>
        </div>
      </div>
    );
  }
);

export default EditVendorPopup;
